.container {
    margin: 20px 10px 0;
}

.table-container, .info-table {
    overflow-x: auto;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #000;
    color: #FFF;
    cursor: col-resize;
}

h3, h4 {
    margin: 5px;
  text-align: center;
}