.App {
  text-align: center;
}

@media only screen and (max-width: 850px) {
  .App {
    display: none;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.AppContainer{
  height: 100vh;
  width: 100%;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar:horizontal{
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(110, 109, 109, 0.582);
          box-shadow: inset 0 0 5px rgba(110, 109, 109, 0.582);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(110, 109, 109, 0.452);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #77727293;
}

.loadingCentered{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

*:focus {
  outline: none !important;
}

.nav-link {
  padding: 0;
  font-size:0.875rem;
}

.nav-link.active {
  box-shadow: initial !important;
  background: transparent !important;
  color: #E1714C !important;
  border-radius: 0 !important;
}

/* ChangePasswordModal */

.crossIcon {
  position: absolute;
  top: -37px;
  right: 10px;
  font-size: 1rem;
}

.changePasswordInputField{
  margin-bottom: 20px !important;
}

/* OpaqueLoading */
.opaqueLoading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.18);
  overflow: hidden;
  z-index: 9999;
}

.opaqueLoading img {
  width: 300px;
  height: 100px;
  transition: transform 0.5s;
  transform-origin: center center; /* Set the transform origin to the center */
}

.opaqueLoading.flipped img {
  transform: rotateY(180deg);
}


.lighterBackdrop{
  background-color: rgba(255, 255, 255, 0.75);
}

#proofEditorDialog .MuiDialog-paper{
  overflow-y:hidden;
}

.cursorPointer{
  cursor: pointer;
}

.payrollGrid .MuiDataGrid-row{
  cursor: pointer;
}

.successRow{
  background-color: #D1E7DD !important; 
}
.selected_employee_row{
  background-color: #e1714c !important;
    color: white !important;
}

.employee_row{
  cursor: pointer;
}

.employee_row:hover{
  background: #ffe2d8 !important;
}

.failureRow{
  background-color: #F8D7DA !important;
}

.dataGridStyleChanges .MuiDataGrid-cell	{
  font-family: "Montserrat";
  font-size: 13px;
}

.dataGridStyleChanges .MuiDataGrid-columnHeaderTitle{
  font-family: "Montserrat";
  font-size: 13px;
}

@media (min-width:900px) {
  .nav-link.active{
    border-bottom: 1px solid #E1714C !important;
    font-weight: 500;
  }
}

.active_row{
  background: #d1e7dd !important;
    color: #146c43 !important;
}
.effectStyle {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.editable-cell {
  background-color: #e0e0e0; /* Customize the background color as needed */
  font-weight: bold; /* Customize the font weight as needed */
}

.custom-datagrid-header {
  background-color: #146c43; 
}

.incentive-management {
  max-width: 900px;
  margin: 50px auto;
  padding: 20px;
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  /* background-color: #f9f9f9cb; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  font-family: Arial, sans-serif;
}

.incentive-managementtitle {
  text-align: center;
  font-size: 1.8rem;
  padding: 5px;
  color: #333;
  margin-bottom: 20px;
}

.incentive-managementform-group {
  margin-bottom: 35px;
}

.incentive-managementform-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  margin-top: 25px;
  color: #555;
  font-size: 20px;
}

.incentive-managementform-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.fincentive-managementorm-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.incentive-managementrange-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rincentive-managementange-input {
  flex: 1;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.incentive-managementsubmit-button {
  display: block;
  width: 100%;
  padding: 10px;
  /* background-color: #007bffd3; */
  /* color: white; */
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 4px;
  /* transition: background-color 0.3s ease; */
}
